/**
 * Contact page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Contactinformation from "../components/contactinformation"
import Hero from "../components/hero"
import Hubspot from "../components/hubspotform"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import "../styles/pages/contact.scss"

/* Page function declaration */
const ContactPage = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageContact

  //CTA Footer page LEARN
  var ctaFooterLearn= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "learn"
  })

  return (
    <Layout className="contact">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Section Hero */}
      <Hero colorShape="bluegrey" classNamePage="contact" />

      {/* Section H1 */}
      <Sectionh1content
        titleH1={dataAcf.titleGeneralContact}
        contentH1={dataAcf.contentSupport}
        classNameParent="support-hours"
        children={<Contactinformation />} />


      {/* Main content */}
      <section id="contact-form" className="section-contact-form main-section bluegrey">
        <div className="content-general">
          <h2 className="title-level-2">{dataAcf.titleForm}</h2>
          <div className="content-form" dangerouslySetInnerHTML={{ __html:dataAcf.contentForm}} />
        </div>
        <Hubspot formIdentifier="c50e8d33-ba9a-47cd-b81f-afaa858ae9ad" />
      </section>

      {/* Section CtaFooter */}
      {ctaFooterLearn.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="learn" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegrey" />
      })}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "contact"}) {
      acfPageContact {
        titleGeneralContact
        contentSupport
        titleForm
        contentForm
      }
      seo {
        metaDesc
        title
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`

/* Export page informations */
export default ContactPage
