/**
 * Const with no params and UseStaticQuery
 * Contact information component
 * Display the contact information: phone and email with circle for svg icon
 */
/* Import section */
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Iconcontactblue from "../images/icons/iconcontactblue.inline.svg"
import Iconsend from "../images/icons/iconsend.inline.svg"
import "../styles/components/contactinformation.scss"

/* Declaration class */
const Contactinformation = () => {
  const data = useStaticQuery(
    graphql`
      query {
        options: wpPage(slug: {eq: "options-website"}) {
          acfPageOptions {
            supportPhone
            supportEmail
          }
        }
      }
    `
  )

  return (
    <ul className="contact-information">
      <li><span><Iconcontactblue className="svg-icon-contactinfo" /></span><a href={`tel:+${data.options.acfPageOptions.supportPhone}`}>{data.options.acfPageOptions.supportPhone}</a></li>
      <li><span><Iconsend className="svg-icon-contactinfo" /></span><a href={`mailto:${data.options.acfPageOptions.supportEmail}`}>{data.options.acfPageOptions.supportEmail}</a></li>
    </ul>
  )
}

export default Contactinformation

