/**
 * Class element
 * HubSpot form component
 * Display a form from HubSpot
 */
/* Import section */
import React, { Component } from "react"
import PropTypes from "prop-types"
import "../styles/components/hubspotform.scss"

/* Declaration class */
class HubspotForm extends Component {

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '166457',
          formId: this.props.formIdentifier,
          target: '#hubspotForm'
        })
      }
    });
  }

  render () {
    return (
      <div id="hubspotForm" className="hubspot-form"></div>
    );
  }
}

/* Function propTypes declaration */
HubspotForm.propTypes = {
  formIdentifier: PropTypes.string,
}

/* Export class */
export default HubspotForm
